import { Config, Type } from './config';

export const environment = {
  version: '{BUILD_VERSION}',
  production: false,
  name: 'uat-msm',
  api: 'https://api-internal-uat.elevateforbusiness.id/internal',
  base_website_url: 'https://commandcenter-uat.elevateforbusiness.id/',
  Base_api_url: 'https://api-internal-uat.elevateforbusiness.id',
  Auth_Endpoint: 'https://api-internal-uat.elevateforbusiness.id/internal/api/auth',
  Parsing_service_Endpoint: 'https://api-internal-uat.elevateforbusiness.id/internal/api/dps',
  Bond_Endpoint: 'https://api-internal-uat.elevateforbusiness.id/internal/api/v1/esbn',
  // Deprecated: use External_Endpoint instead.
  CTOS_EndPoint: 'https://api-internal-uat.elevateforbusiness.id/internal/api/ext',
  // Deprecated: use External_Endpoint instead.
  Questnet_EndPoint: 'https://api-internal-uat.elevateforbusiness.id/internal/api/ext',
  External_EndPoint: 'https://api-internal-uat.elevateforbusiness.id/internal/api/ext',
  FA_EndPoint: 'https://api-internal-uat.elevateforbusiness.id/internal/api/v2/fa',
  Document_EndPoint_V3:
    'https://api-internal-uat.elevateforbusiness.id/internal/api/docs_management/v3',
  Finops_Comms_Service_Endpoint: 'https://api-internal-uat.elevateforbusiness.id/internal/api/fcs', // new cf orchestration service
  DocGen_EndPoint: 'https://api-internal-uat.elevateforbusiness.id/internal/api/dg',
  Finance_Endpoint: 'https://api-internal-uat.elevateforbusiness.id/internal/api/fs',
  Finance_Endpoint_V2: 'https://api-internal-uat.elevateforbusiness.id/internal/api/fs/v2',
  Finance_Pairing_Endpoint:
    'https://api-internal-uat.elevateforbusiness.id/internal/api/finance_pairing/v1',
  Member_EndPoint: 'https://api-internal-uat.elevateforbusiness.id/internal/api/ms',
  Member_EndPoint_V2: 'https://api-internal-uat.elevateforbusiness.id/internal/api/v2/members',
  Member_EndPoint_V3: 'https://api-internal-uat.elevateforbusiness.id/internal/api/v3/members',
  SOA_EndPoint_V2: 'https://api-internal-uat.elevateforbusiness.id/internal/api/v2/soa-reports',
  Loan_EndPoint: 'https://api-internal-uat.elevateforbusiness.id/internal/api/ln',
  LookUpNodeJS_EndPoint: 'https://api-internal-uat.elevateforbusiness.id/internal/api/lu/p',
  Loyalty_EndPoint: 'https://api-internal-uat.elevateforbusiness.id/internal/api/v1/loyalty',
  SMS_EndPoint: 'https://api-internal-uat.elevateforbusiness.id/internal/api/sms',
  Scheduler_EndPoint: 'https://api-internal-uat.elevateforbusiness.id/internal/api/sc',
  Logout_URI: 'https://api-internal-uat.elevateforbusiness.id/internal/api/logout?redirect=',
  Logout_Page_URL:
    'https://api-internal-uat.elevateforbusiness.id/internal/login/logout.html?home=',
  Login_URI: 'https://api-internal-uat.elevateforbusiness.id/internal/login/?redirect_uri=',
  Notification_Endpoint: 'https://api-internal-uat.elevateforbusiness.id/internal/api/notification',
  Email_Endpoint: 'https://api-internal-uat.elevateforbusiness.id/internal/api/email',
  Staff_Endpoint: 'https://api-internal-uat.elevateforbusiness.id/internal/api/ss',
  User_Endpoint: 'https://api-internal-uat.elevateforbusiness.id/internal/api',
  Kyc_Endpoint: 'https://api-internal-uat.elevateforbusiness.id/internal/api/kyc/v1',
  CustomModule_Endpoint: 'https://api-internal-uat.elevateforbusiness.id:30051',
  MicroLoan_Endpoint: 'https://uat-microloan.fundingasiagroup.com',
  MicroLoan_Docs_Gen_Endpoint:
    'https://api-internal-uat.elevateforbusiness.id/internal/api/docs_gen',
  MicroLoan_Internal_Endpoint: 'https://api-internal-uat.elevateforbusiness.id/internal/api/ml',
  Underwriting_Endpoint: 'https://api-internal-uat.elevateforbusiness.id/internal/api/credit',
  Credit_Endpoint: 'https://api-internal-uat.elevateforbusiness.id/internal/api/credit/v2',
  v2_url: 'https://api-internal-uat.elevateforbusiness.id/internal/api/v2',
  Partnership_Endpoint: 'https://api-internal-uat.elevateforbusiness.id/internal/api/partners',
  Collection_Endpoint:
    'https://api-internal-uat.elevateforbusiness.id/internal/api/v1/loan-collections',
  Dispatch_Endpoint: 'https://api-internal-uat.elevateforbusiness.id/internal/api/dispatch',
  Facility_Endpoint: 'https://api-internal-uat.elevateforbusiness.id/internal/api/facility',
  Investor_Channelling_Endpoint: 'https://api-internal-uat.elevateforbusiness.id/internal/api/ics',
  Facility_Endpoint_V2: 'https://api-internal-uat.elevateforbusiness.id/internal/api/facility/v2',
  ELO_EndPoint: 'https://api-internal-uat.elevateforbusiness.id/internal/api/elo_rating',
  ELO_Rating_EndPoint: 'https://api-internal-uat.elevateforbusiness.id/internal/api/credit/v2/elo',
  Paylater_EndPoint: 'https://api-internal-uat.elevateforbusiness.id/internal/api/paylater',
  myTukar_account_Id: 819726,
  carlistbid_account_id: 833585,
  MUV_account_Id: 819776,
  launch_darkly_client_id: '6540727e3a24c412a977e40b',
  Bolt_Partner_EndPoint: 'https://api-internal-uat.elevateforbusiness.id/internal/api/bpp',
  Borrower_Backend_Service_Endpoint:
    'https://api-internal-uat.elevateforbusiness.id/internal/api/bbs',
  Ekyc_Esign_Service_Endpoint: 'https://api-internal-uat.elevateforbusiness.id/internal/api/ees',
  Bank_Integration_Service_Endpoint:
    'https://api-internal-uat.elevateforbusiness.id/internal/api/bis',
  Business_Account_Endpoint: 'https://api-internal-uat.elevateforbusiness.id/internal/api/bas',
  scfs: 'https://staging-scfs2.fundingasiagroup.com',
  bowIDOrigin: 'https://apply-uat.modalku.co.id',
  carsome_account_Id: 1068468,
  ezauto_account_Id: 1211829,
  hideNonMKItem: false, // For ID Audit
  hideNonFSItem: false, // For ID Audit
  download_soa: `https://kong-internal-uat.fundingasiagroup.com/internal/api/dg/v1/document`,
  type: new Config(Type.UAT),
  template_id: '78e0017d-65ab-4c37-8439-2426eca675b9',
  sheet_name: 'Sheet1',
  qlmProductIds: [16, 13, 209],
  enablePrivy: true,
  productIds: {
    islamicMicrofinancingMY: 210,
    islamicEcommerceMY: 211,
  },
  loanLevelSOA: {
    SG: {
      fs_capital: {
        template_id: '0ab3b9f9-6b01-4c89-9dc6-e1265d0f79d0',
      },
      fspl: {
        template_id: '9f3a9cba-7bae-41de-a555-30785811984f',
      },
    },
  },
  Appsmith_Business_Account_Lifecycle_Url:
    'https://appsmith-uat.elevateforbusiness.id/app/elevate-internal-tools/business-accounts-lifecycle-65465be65ca66228829eff89?branch=master&embed=true&ssoTrigger=oidc',
  Appsmith_Business_Account_Details_Url:
    'https://appsmith-uat.elevateforbusiness.id/app/elevate-internal-tools/business-accounts-details-65465be65ca66228829eff8d?branch=master&embed=true&ssoTrigger=oidc',
  Appsmith_Scheduler_Url:
    'https://appsmith-uat.elevateforbusiness.id/app/scheduler-tool/create-new-schedule-664f660aaa76351d04b7a926',
  masterCustomerData: {
    individualsUrl: '',
    companiesUrl: '',
  },
  datadogConfiguration: {
    applicationId: 'd3901d1d-6061-471e-9270-feb3e4e8006c',
    clientToken: 'pubb5aa584c9aab4cf591097f9bfa2d2c1b',
  },
  sentryDsn:
    'https://a3e5b9b4eedc6a0d23b1f6021d8cdc21@o103441.ingest.us.sentry.io/4507887848194048',
};
